import store from "@/store";

export default class ProjectModel {
    lang = store.getters._getLanguages;

    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setName(item);
        this._setStatus(item);
        this._setCustomer(item);
        this._setAddress(item);
        this._setIsFinished(item);
        this._setImages(item);

    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }


    /**
     * set title
     * @param item
     * @private
     */
    _setTitle(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `title_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set description
     * @param item
     * @private
     */
    _setDescription(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `description_${this.lang[i]}`;
            this[key] = item[key];
        }
    }


    /**
     * set name
     * @param item
     * @private
     */
    _setName(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `name_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set status
     * @param item
     * @private
     */
    _setStatus(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `status_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set customer
     * @param item
     * @private
     */
    _setCustomer(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `customer_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set address
     * @param item
     * @private
     */
    _setAddress(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `address_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set isFinished
     * @param finished
     * @private
     */
    _setIsFinished({finished}) {
        this.isFinished = finished;
    }


    /**
     * set image
     * @param fileUrl
     * @private
     */
    _setImages({fileUrls}) {
        let images = [];
        fileUrls.forEach(item=>{
            if(item.isMain) {
                this.image = item.link;
                this.imageId = item.id;
            }
            else {
                images.push(item);
            }
        });
        this.images = images;
    }
}
