import SettingsModel from "@/views/settings/models/settings.model";

export default {
    data() {
        return {
            user: {},
        };
    },
    methods: {
        changePass() {
            if (
                this.user.email &&
                this.user.currentPass &&
                this.user.newPass &&
                this.user.confirmPass
            ) {
                if (this.user.newPass === this.user.confirmPass) {
                    this.$store
                        .dispatch("login/changePass", new SettingsModel(this.user))
                        .then((res) => {
                            if (res) {
                                this.user = {};
                            }
                        });
                } else {
                    let errMessage = "Confirm password is wrong";
                    this.$toast.error(errMessage);
                }
            }
        },
    },
};
