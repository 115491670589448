import CarouselPostModel from "@/views/carousel/models/carousel-post.model";
import getLanguages from "@/mixins/get-languages";
import imageInput from "@/mixins/image-input";

export default {
    name: "carousel-add",
    data() {
        return {
            item: {}
        }
    },
    computed: {
      isEditPage() {
          return this.$route.meta.edit;
      }
    },
    methods: {
        approve() {
            let data = new CarouselPostModel(this.item);
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }
            if(this.isEditPage) {
                this.$store.dispatch("carousel/updateItem", {id: data.id, data: formData});
            }
            else {
                this.$store.dispatch("carousel/setItem", formData).then((res) => {
                    if (res) {
                        this.item = {};
                        this.resfresh = true;
                    }
                });
            }
        },
    },
    created() {
        let id = this.$route.params.id;
        if (this.isEditPage) {
            this.$store.dispatch("carousel/getItemById", id).then((res) => {
                if (res) {
                    this.item = res;
                }
            });
        }
    },
    mixins: [imageInput,getLanguages]
};
