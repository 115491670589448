import axios from "axios";
import api from '@/core/api';
import FeatureModel from "../models/feature.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.feature)
            .then((res) => {
                let data = res.data.features.items;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new FeatureModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.feature, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.feature + `/${payload.id}`, payload.data)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    deleteItem({commit}, id) {
        return axios
            .delete(api.feature + `/${id}`)
            .then(() => {
                commit("deleteItem", id);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.feature + `/${id}`)
            .then((response) => {
                return new FeatureModel(response.data.feature);
            })
            .catch(() => {
                return false;
            });
    },
}