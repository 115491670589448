import axios from "axios";
import api from '@/core/api';
import SuggestModel from "../models/suggest.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.suggest)
            .then((res) => {
                let data = res.data.suggestions.items;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new SuggestModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.suggest, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.suggest + `/${payload.id}`, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    deleteItem({commit}, id) {
        return axios
            .delete(api.suggest + `/${id}`)
            .then(() => {
                commit("deleteItem", id);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.suggest + `/${id}`)
            .then((response) => {
                return new SuggestModel(response.data.suggest);
            })
            .catch(() => {
                return false;
            });
    },
}