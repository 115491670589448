import login from '../components/layout/login/store';
import lead from '../views/lead/store';
import about from '../views/about/store';
import contact from '../views/contact/store';
import skills from '../views/skills/store';
import reports from '../views/reports/store';
import feature from '../views/feature/store';
import carousel from '../views/carousel/store';
import suggest from '../views/suggest/store';
import project from '../views/project/store';

export default {
    login,
    lead,
    about,
    contact,
    skills,
    reports,
    feature,
    carousel,
    suggest,
    project,
}
