export default {
    name: "login",
    data() {
        return {
            user: {
                email: "",
                password: '',
            },
        };
    },
    methods: {
        login() {
            this.$store.dispatch("login/login", this.user);
        },
    },
};
