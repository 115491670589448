import {mapGetters} from "vuex";
import LeadPostModel from './models/lead-post.model';
import imageInput from '@/mixins/image-input.js';
import getLanguages from "@/mixins/get-languages";

export default {
    name: "lead",
    data() {
        return {
            type: null,
            item: {},
        }
    },
    computed: {
        ...mapGetters({
                leadTypes: 'lead/_getItems'
            }
        )
    },
    methods: {
        getLead() {
            this.$store.dispatch('lead/getItemById', this.type).then((res) => {
                if (res) {
                    this.item = {...res};
                }
            })
        },
        updateLead() {
            let data = new LeadPostModel(this.item);
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }
            this.$store.dispatch('lead/updateItem', {data: formData, id: data.id});
        }
    },
    created() {
        this.$store.dispatch('lead/getItems');
    },
    mixins: [imageInput,getLanguages],

};