export default class ContactPostModel {
    constructor(item) {
        this._setId(item);
        this._setEmail(item);
        this._setLocation(item);
        this._setPhone(item);

    }
    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }
    /**
     * set email
     * @param email
     * @private
     */
    _setEmail({email}) {
        this.email = email;
    }

    /**
     * set location
     * @param location
     * @private
     */
    _setLocation({location}) {
        this.location = location;
    }

    /**
     * set phone
     * @param phone
     * @private
     */
    _setPhone({phone}) {
        this.phone = phone;
    }

}