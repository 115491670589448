import axios from "axios";
import api from '@/core/api';
import ReportsModel from "../models/reports.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.reports+'?PageNumber=1&PageSize=1000')
            .then((res) => {
                let data = res.data.financialReport.items;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new ReportsModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.reports, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.reports + `/${payload.id}`, payload.data)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    deleteItem({commit}, id) {
        return axios
            .delete(api.reports + `/${id}`)
            .then(() => {
                commit("deleteItem", id);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.reports + `/${id}`)
            .then((response) => {
                return new ReportsModel(response.data.financialReport);
            })
            .catch(() => {
                return false;
            });
    },
}