import ProjectPostModel from "@/views/project/models/project-post.model";
import getLanguages from "@/mixins/get-languages";
import imageInput from "@/mixins/image-input";

export default {
    name: "project-add",
    data() {
        return {
            item: {},
            images: [],
            deletedImages: [],
        }
    },
    computed: {
        isEditPage() {
            return this.$route.meta.edit;
        },
        newImages() {
            return this.images.filter(i => {
                if (!i.id) return i;
            })
        }
    },
    methods: {
        approve() {
            let data = new ProjectPostModel({
                ...this.item,
                deletedImages: this.deletedImages,
                newImages: this.newImages
            });
            let formData = new FormData();
            for (let key in data) {
                if (key !== 'files') {
                    formData.append(key, data[key]);
                }
            }
            for (let i = 0; i < data.files.length; i++) {
                let obj = data.files[i];
                for (let key in obj) {
                    formData.append(`Files[${i}].${key}`,obj[key])
                }
            }
            if (this.isEditPage) {
                this.updateData(data.id, formData)
            } else {
                this.postData(formData)
            }
        },
        postData(payload) {
            this.$store.dispatch("project/setItem", payload).then((res) => {
                if (res) {
                    this.item = {};
                    this.resfresh = true;
                    this.images=[];
                    this.deletedImages=[];
                }
            });
        },
        updateData(id, payload) {
            this.$store.dispatch("project/updateItem", {id: id, data: payload});
        },
        addImage() {
            this.$refs.newProjectImage.click();
        },
        newImageChange(e) {
            let newImage = {};
            let file = e.target.files[0];
            if (file) {
                newImage.link = URL.createObjectURL(file);
            }
            newImage.file = file;
            this.images.push(newImage);
            this.$refs.newProjectImage.value = '';
        },
        deleteImage(i) {
            if (this.images[i].id) {
                this.deletedImages.push(this.images[i].id);
            }
            this.images.splice(i, 1);
        }
    },
    created() {
        let id = this.$route.params.id;
        if (this.isEditPage) {
            this.$store.dispatch("project/getItemById", id).then((res) => {
                if (res) {
                    this.item = res;
                    this.images = res.images;
                }
            });
        }
    },
    mixins: [imageInput, getLanguages]
};
