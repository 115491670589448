import axios from "axios";
import api from '@/core/api';
import LeadModel from "../models/lead.model";
import LeadTypeModel from "../models/lead-type.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.leadTypes)
            .then((res) => {
                let data = res.data.dropdowns;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new LeadTypeModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.lead + `/${payload.id}`, payload.data)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.leadByType + `/${id}`)
            .then((response) => {
                return new LeadModel(response.data.lead);
            })
            .catch(() => {
                return false;
            });
    },
}