import Vue from 'vue';
import SectionTitle from './section-title/section-title.component.vue';
import EmptyAlert from './empty-alert/empty-alert.component.vue';
import ImageInput from './image-input/image-input.component.vue';
import FileInput from './file-input/file-input.component.vue';
import RemoveDialog from './remove-dialog/remove-dialog.component.vue';
import LanguageTitle from './language-title/language-title.component.vue';

Vue.component("section-title", SectionTitle);
Vue.component("empty-alert", EmptyAlert);
Vue.component("image-input", ImageInput);
Vue.component("file-input", FileInput);
Vue.component("remove-dialog", RemoveDialog);
Vue.component("language-title", LanguageTitle);
