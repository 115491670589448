export default class SettingsModel {

    constructor(item) {
        this._setCurrent(item);
        this._setEmail(item);
        this._setNew(item);
        this._setConfirm(item);
    }


    _setEmail({email}) {
        this.email = email;
    }
    /**
     * set currentPassword
     * @param currentPass
     * @private
     */
    _setCurrent({currentPass}) {
        this.currentPassword = currentPass;
    }

    /**
     * set newPassword
     * @param newPass
     * @private
     */
    _setNew({newPass}) {
        this.newPassword = newPass;
    }
    /**
     * set confirmNewPassword
     * @param confirmPass
     * @private
     */
    _setConfirm({confirmPass}) {
        this.confirmNewPassword = confirmPass;
    }


}
