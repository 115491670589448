import store from "@/store";

export default class SuggestModel {
    lang = store.getters._getLanguages;

    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setDescription(item);

    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set title
     * @param item
     * @private
     */
    _setTitle(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `title_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set description
     * @param item
     * @private
     */
    _setDescription(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `description_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

}