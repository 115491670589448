import Vue from "vue";
import Vuex from "vuex";
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import modules from './modules';
// import createPersistedState from "vuex-persistedstate";
// import SecureLS from "secure-ls";

Vue.use(Vuex);

// let ls = new SecureLS({isCompression: false});

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules,
    // plugins: [
    //     createPersistedState({
    //         storage: {
    //             getItem: (key) => ls.get(key),
    //             setItem: (key, value) => ls.set(key, value),
    //             removeItem: (key) => ls.remove(key),
    //         },
    //     }),],
});