import router from "@/router";

export default {
    computed: {
        currentPage() {
            return this.$route.meta.name;
        },
    },
    methods: {
        logout() {
            localStorage.removeItem(`admin.${process.env.VUE_APP_NAME}`);
            router.push({name: 'login'})
        },
    },
};
