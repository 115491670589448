import getLanguages from "@/mixins/get-languages";
import SuggestPostModel from "@/views/suggest/models/suggest-post.model";

export default {
    name: "suggest-add",
    data() {
        return {
            item: {}
        }
    },
    computed: {
      isEditPage() {
          return this.$route.meta.edit;
      }
    },
    methods: {
        approve() {
            let data = new SuggestPostModel(this.item);

            if(this.isEditPage) {
                this.$store.dispatch("suggest/updateItem", data);
            }
            else {
                this.$store.dispatch("suggest/setItem", data).then((res) => {
                    if (res) {
                        this.item = {};
                    }
                });
            }
        },
    },
    created() {
        let id = this.$route.params.id;
        if (this.isEditPage) {
            this.$store.dispatch("suggest/getItemById", id).then((res) => {
                if (res) {
                    this.item = res;
                }
            });
        }
    },
    mixins: [getLanguages]

};