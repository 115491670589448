export default {
    users: 'users',
    login: 'identity/login',
    statistics: 'reportStatistic',
    leadTypes: 'leadtypes',
    lead: 'lead',
    leadByType: 'leadByTypeId',
    aboutTypes: 'abouttypes',
    about: 'about',
    aboutByType: 'aboutByTypeId',
    contact: 'contact',
    skills: 'workSkill',
    reports: 'financialReport',
    feature: 'feature',
    carousel: 'carousel',
    suggest: 'suggest',
    project: 'project',
    settings: 'identity/changePassword',

}
