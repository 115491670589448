import axios from "axios";
import api from '@/core/api';
import AboutModel from "../models/about.model";
import AboutTypeModel from "@/views/about/models/about-type.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.aboutTypes)
            .then((res) => {
                let data = res.data.dropdown;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new AboutTypeModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.about + `/${payload.id}`, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.aboutByType + `/${id}`)
            .then((response) => {
                return new AboutModel(response.data.about);
            })
            .catch(() => {
                return false;
            });
    },
}