import router from '../router';

export default {
    initAuth() {
        let token = localStorage.getItem(`admin.${process.env.VUE_APP_NAME}`);
        if (token)
            return true;
        router.push({name: "login"});
        return false;
    },
}
