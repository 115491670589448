import axios from "axios";
import api from '@/core/api';
import SkillsModel from "../models/skills.model";

export default {

    getItems({commit}) {
        return axios
            .get(api.skills)
            .then((res) => {
                let data = res.data.workSkills.items;
                commit(
                    "setItems",
                    data.map((i) => {
                        return new SkillsModel(i);
                    })
                );
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    setItem(_, payload) {
        return axios
            .post(api.skills, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    updateItem(_, payload) {
        return axios
            .put(api.skills + `/${payload.id}`, payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    deleteItem({commit}, id) {
        return axios
            .delete(api.skills + `/${id}`)
            .then(() => {
                commit("deleteItem", id);
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    getItemById(_, id) {
        return axios
            .get(api.skills + `/${id}`)
            .then((response) => {
                return new SkillsModel(response.data.workSkill);
            })
            .catch(() => {
                return false;
            });
    },
}