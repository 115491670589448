import store from "@/store";

export default class ProjectModel {
    lang = store.getters._getLanguages;
    files = [];

    constructor(item) {
        this._setId(item);
        this._setTitle(item);
        this._setDescription(item);
        this._setName(item);
        this._setStatus(item);
        this._setCustomer(item);
        this._setAddress(item);
        this._setIsFinished(item);
        this._setImages(item);
    }

    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }


    /**
     * set title
     * @param item
     * @private
     */
    _setTitle(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `title_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set description
     * @param item
     * @private
     */
    _setDescription(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `description_${this.lang[i]}`;
            this[key] = item[key];
        }
    }


    /**
     * set name
     * @param item
     * @private
     */
    _setName(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `name_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set status
     * @param item
     * @private
     */
    _setStatus(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `status_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set customer
     * @param item
     * @private
     */
    _setCustomer(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `customer_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set address
     * @param item
     * @private
     */
    _setAddress(item) {
        for (let i = 0; i < this.lang.length; i++) {
            let key = `address_${this.lang[i]}`;
            this[key] = item[key];
        }
    }

    /**
     * set isFinished
     * @param finished
     * @private
     */
    _setIsFinished({isFinished}) {
        if (isFinished)
            this.finished = isFinished;
        else
            this.finished = false;

    }


    /**
     * set Images
     * @param Images
     * @private
     */
    _setImages(item) {
        if (!(item.image && item.image.length)) {
            let obj = {
                isMain: true,
                file: item.image
            }
            if (item.imageId) {
                obj.id = item.imageId
            }
            this.files.push(obj);
        }

        let arr = item.newImages.concat(item.deletedImages);
        for (let i = 0; i < arr.length; i++) {
            let obj = {}
            if (isNaN(arr[i])) {
                obj = {
                    isMain: false,
                    file: arr[i].file
                }
            } else {
                obj = {
                    id: arr[i]
                }
            }
            this.files.push(obj);
        }
    }
}
