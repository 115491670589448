import ContactPostModel from './models/contact-post.model';

export default {
    name: "contact",
    data() {
        return {
            item: {}
        }
    },
    methods: {
        updateContact() {
            let item = this.$store.getters['contact/_getItem'];
            let payload = new ContactPostModel(this.item)
            if (item) {
                this.$store.dispatch('contact/updateItem', payload);
            } else {
                this.$store.dispatch('contact/setItem', payload).then((res)=>{
                    if(res) {
                        this.getStatistics();
                    }
                });
            }
        }
    },
    created() {
        this.$store.dispatch('contact/getItems').then(() => {
            let item = this.$store.getters['contact/_getItem'];
            if (item) {
                this.item = item;
            }
        });
    }
};